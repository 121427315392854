import React, { useEffect, useState, useMemo } from "react";
import { useTranslation } from 'react-i18next';
import ReactWebChat, { createDirectLine } from "botframework-webchat";
import {
  createChatStyleSet,
  createStyleOptions,
} from "../assets/constants";
import Header from "./Header";
import useMediaQuery from "../hooks/mediaquery";
import styles from "./ChatBot.module.scss";
import { loading } from "../assets/images";

const ChatBot = () => {
  const { t } = useTranslation();
  const [directLine, setDirectLine] = useState(null);
  const isMobile = !useMediaQuery("(min-width: 768px)");

  useEffect(() => {
    fetch("/token/generateToken")
      .then(key => key.json())
      .then((key) => {
        const { token, id } = key;
        setDirectLine(createDirectLine({ token, id }));
      })
      .catch(err => console.error(err));
  }, []);

  const styleSet = useMemo(()=>createChatStyleSet(isMobile), [isMobile]);
  const styleOptions = useMemo(createStyleOptions, []);

  //update send box button
  useEffect(() => {
    const sendBoxButton = document.getElementsByClassName(t('sendBox.className'));
    if (sendBoxButton && sendBoxButton[0]) {
      const sendButton = document.createElement("span");
      sendButton.className = styles.sendBoxButton;
      sendButton.textContent = t('sendBox.buttonTitle');
      sendBoxButton[0].replaceWith(sendButton);
    }
  }, [directLine]);

  const activityStatusMiddleware = () => (next) => (args) => {
    const {
      activity: {
        from: { role, name },
        timestamp,
      },
      sendState,
      sameTimestampGroup,
    } = args;

    if (sendState === t('sendState.sendingStatus')) {
      return (
        <span
          className={`${styles.activityStatus} ${styles.activityStatus__sendStatus}`}
        >
          {t('sendState.sendingTitle')}&hellip;
        </span>
      );
    } else if (sendState === t('sendState.sendFailedStatus')) {
      return (
        <span
          className={`${styles.activityStatus} ${styles.activityStatus__sendStatus}`}
        >
          {t('sendState.sendFailedTitle')}
        </span>
      );
    } else if (!sameTimestampGroup) {
      return (
        <span className={styles.activityStatus}>
          <span>{role === t('roleCheck.role') ? `${t('roleCheck.userName')} at ` : `${name} at `}</span>
          <span>
            {new Date(timestamp).toLocaleTimeString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })}
          </span>
        </span>
      );
    }

    return next(args);
  };

  return (
    <div>
      <Header />
      <div className={styles.chatContainer}>
        {directLine ? (
          <ReactWebChat
            directLine={directLine}
            userID={directLine.id}
            styleOptions={styleOptions}
            styleSet={styleSet}
            activityStatusMiddleware={activityStatusMiddleware}
            sendTypingIndicator={true}
          />
        ) : (
          <img src={loading} alt="loading" className={styles.loading} />
        )}
      </div>
    </div>
  );
};

export default ChatBot;
