import { createStyleSet } from "botframework-webchat";


export const createChatStyleSet = (isMobile) => {
  const styleSet = createStyleSet({
    primaryFont: `"open sans", sans-serif`,
    letterSpacing: "-0.24px",
    lineHeight: "23px",
    paddingRegular: 25,
    paddingWide: 25,
    fontSizeSmall: "17px",

    bubbleBackground: "#F8F8F8",
    bubbleTextColor: "#101820",
    bubbleBorderRadius: 8,
    bubbleBorderStyle: "solid",
    bubbleBorderColor: "#F8F8F8",

    bubbleFromUserBackground: "#E4F5F5",
    bubbleFromUserTextColor: "#101820",
    bubbleFromUserBorderRadius: 8,
    bubbleFromUserBorderStyle: "solid",
    bubbleFromUserBorderColor: "#F8F8F8",

    bubbleNubOffset: 50, // Adds a 50px space below each bot message
    bubbleFromUserNubOffset: 50, // Adds a 50px space below each user message
  });

  styleSet.sendBoxButtonColorOnHover = "white";
  styleSet.sendBoxHeight = 70;
  styleSet.sendBox = {
    boxSizing: "border-box",
    height: "70px",
    border: "1px solid #C5C5C5",
    borderRadius: "8px",
    backgroundColor: "#FFFFFF",
  };

  styleSet.sendBoxButton = {
    margin: "10px",
    height: "50px",
    width: isMobile ? "auto":"160px",
    borderRadius: "8px",
    backgroundColor: "#588935",
    borderColor: "#588935"
  };

  return styleSet;
};

export const createStyleOptions = () => {
  const styleOptions = {
    hideUploadButton: true,
    groupTimestamp: true,
  };

  return styleOptions;
};
