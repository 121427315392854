import React from "react";
import { useTranslation } from 'react-i18next';
import classes from './Header.module.scss';

const Header = () => {
    const { t } = useTranslation();

  return (
    <div className={classes.navContainer}>
      <div className={classes.titleContainer}>
        <h1 className={classes.header}>{t('chat.title')}</h1>
      </div>
      <div className={classes.rectangle}></div>
      <div className={classes.bottomBorder}></div>
    </div>
  );
};

export default Header